
import LargeSpace from '../components/LargeSpace';
// import NavbarOffCanvas from '../components/NavbarOffCanvas';
import TestComponent from '../components/TestComponent';




const TestView = () => {
    return (
      <div>
        <LargeSpace/>
        {/* <NavbarOffCanvas/> */}
      <TestComponent/> {/* Just keep this for colour reference. Delete when done production */}
      </div>
      
    )
  }

  export default TestView;