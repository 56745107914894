import About from '../components/About'
import About3 from '../components/About3';



const AboutView = () => {
    return (
      <div>
      <About />
      {/* <div className='border'></div> */}
      {/* <About3/> */}

      </div>
      
    )
  }

  export default AboutView;