import React from 'react';
import ContactForm from '../components/ContactForm';

export default function ContactView() {
  return (
    <div>
        <ContactForm/>
    </div>
  )
}
